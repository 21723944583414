html, body {
    transition: color 0.3s ease, background 0.3s ease;
}
.transition-fade {
    transition: opacity 0.3s ease;
    opacity: 1;
}
html.is-animating .transition-fade {
    opacity: 0;
}
html.is-loading {

}
