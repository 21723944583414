body {
  @include above($md + 1) {
    overflow: hidden;
  }
}

header {
  padding: $padding--desktop;
  position: relative;
  z-index: 2;
  &.fit-height {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  #signupLink, #signinLink {
    // line-height: 0.6;
    @include below($md) {
      color: black;
    }
  }
  #signinLink, #logout {
    margin-left: 0.7em;
  }
}

#link-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}

#background-video {
  background: #000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  &::after {
    content: 'Mute';
    pointer-events: none;
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    @include ff-bold();
    text-transform: uppercase;
    color: $color--font-accent;
    padding: 1em;
  }
  &.no-ui {
    &::after {
      display: none;
    }
  }
  &.video-is-muted {
    &::after {
      content: 'Unmute';
    }
  }
  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
  }
  @include portrait() {
    video.landscape {
      display: none
    }
  }
  @include landscape() {
    video.portrait {
      display: none
    }
  }
}

.button-icon {
  display: inline-block;
  height: 0.7em;
  width: auto;
  margin-left: 0.1em;
  fill: currentColor;
}

#site-title {
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  margin-bottom: 0.1em;
  @include below($md) {
    margin-bottom: 0.2em;
  }
}

#countdown, #time-left {
  display: inline-block;
  width: 100%;
  margin-top: 0.15em;
  margin-bottom: 0.1em;
  .number {
    position: relative;
    sup {
      position: absolute;
      top: -0.8em;
      left: 1vmin;
      @include ff-text();
      @include ff-bold();
      @include fs-small();
      @include below($md) {
        font-size: 0.5rem;
      }
    }
  }
}

#countdown {
  sup {
    color: $color--font-primary;
  }
}

#accessLink, #logout {
  position: relative;
  z-index: 10;
}

#logout {
  @include below($md) {
    margin: 0.2em 0 0;
  }
}

[data-router] {
  cursor: pointer;
}

#app {
  width: 100%;
  overflow: hidden;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  @include above($md + 1) {
    height: 100vh;
  }
  @include below($md) {
    height: auto;
    min-height: 40rem;
  }
  .form {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  .errors, .spacer {
    flex: 1;
  }
}

.form {
  .errors {
    @include ff-bold();
    text-transform: uppercase;
    padding: 1em;
    text-align: center;
    @include below($md) {
      text-align: left;
      padding: 1em $padding--desktop;
    }
  }
  button.fs-large {
    // line-height: 0.6;
  }
}

.form-fields {
  display: flex;
  flex: 1 0 auto;
  @include below($md) {
    flex-direction: column;
  }
  & > div {
    // display: flex;
    // flex-direction: column;
    flex: 1;
    overflow: hidden;
    @include above($md + 1) {
      &:not(:last-child) {
        border-right: var(--border-width) $color--font-primary solid;
      }
    }
    label {
      @include ff-bold();
      text-transform: uppercase;
      padding: 1em $padding--desktop 0;
      @include above($md + 1) {
        padding: 1em;
        border-bottom: var(--border-width) $color--font-primary solid;
      }
    }
    input {
      padding: 1em;
      width: 100%;
      color: $color--font-secondary;
      @include below($md) {
        padding: 0.7em $padding--desktop;
        border-bottom: var(--border-width) $color--font-primary solid;
      }
      &::placeholder {
        color: inherit;
      }
    }
    select {
      margin: 1em 1em;
      color: $color--font-secondary;
      @include below($md) {
        margin: 0.7em $padding--desktop;
      }
    }
  }
}
