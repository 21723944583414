$xxl: 1600px;
$lg: 1024px;
$md: 768px;

// Colors
$color--font-primary: #000;
$color--font-secondary: #fff;
$color--font-accent: rgb(0,255,0);
$color--background-main: rgb(0,255,0);
$color--white: #fff;
$color--gray: rgba(0,0,0,0.1);
$color--overlay: rgba(0,0,0,0.3);

$padding--desktop: 7vmin;
$padding--mobile: 1rem;
$padding: $padding--desktop;
$padding--menu: 1.7rem;
$padding--content-space: ($padding--desktop*3);

:root {
  --border-width: 2px;
  --padding: #{$padding--desktop};
  @include below($md) {
    --padding: #{$padding--mobile};
  }
}
