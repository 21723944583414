@font-face {
    font-family: 'Mondwest-Regular';
    src: url('../assets/fonts/Mondwest-Regular.woff2') format('woff2'), url('../assets/fonts/Mondwest-Regular.woff') format('woff');
    font-style: normal;
}

@font-face {
    font-family: 'AkkuratStd-Regular';
    src: url('../assets/fonts/AkkuratStd-Regular.woff2') format('woff2'), url('../assets/fonts/AkkuratStd-Regular.woff') format('woff');
    font-style: normal;
}

@font-face {
    font-family: 'AkkuratStd-Bold';
    src: url('../assets/fonts/AkkuratStd-Bold.woff2') format('woff2'), url('../assets/fonts/AkkuratStd-Bold.woff') format('woff');
    font-style: normal;
}

// Fontfaces
@mixin ff-text() {
    font-family: 'AkkuratStd-Regular', sans-serif;
    letter-spacing: 0.03em;
    line-height: 1;
    &.italic, .italic, em, i {
        font-family: 'AkkuratStd-RegularItalic', sans-serif;
    }
}
@mixin ff-bold() {
    font-family: 'AkkuratStd-Bold', sans-serif;
    letter-spacing: 0.04rem;
}
@mixin ff-pixel() {
    font-family: 'Mondwest-Regular', sans-serif;
    letter-spacing: normal;
    letter-spacing: -0.03em;
}
@mixin ff-italic() {
    font-family: 'AkkuratStd-RegularItalic', sans-serif;
}
.ff-pixel {
    @include ff-pixel();
}
.ff-bold {
    @include ff-bold();
}
.ff-italic {
    @include ff-italic();
}

// Sizes
@mixin fs-small() {
    font-size: 1rem;
}
@mixin fs-normal() {
    font-size: 1rem;
}
@mixin fs-medium() {
    font-size: 2rem;
    @include below($md) {
      font-size: 1.6rem;
    }
}
@mixin fs-lead() {
    font-size: 2rem;
    @include below($md) {
      font-size: 1.6rem;
    }
}
@mixin fs-large() {
    @include above($md + 1) {
      font-size: 17vmin;
    }
    &.ff-text {
      letter-spacing: -0.03em;
      @include above($md + 1) {
        font-size: 15vmin;
      }
    }
    @include below($md) {
      font-size: 4rem;
    }
}
.fs-small {
    @include fs-small();
}
.fs-normal {
    @include fs-normal();
}
.fs-medium {
    @include fs-medium();
}
.fs-large {
    @include fs-large();
}

// Line height
@mixin lh-text() {
    line-height: 1.35;
}
.lh-text {
    @include lh-text();
}

// Styles
@mixin text--lead() {
    @include ff-bold();
    @include fs-lead();
    line-height: 1.2;
    letter-spacing: 0.04em;
}
@mixin text--large-title() {
    @include ff-bold();
    @include fs-large();
}

body, html {
    -webkit-text-size-adjust: 100%;
}
html {
    font-size: 0.8vw;
    @include below($lg) {
      font-size: 0.92vw;
    }
    @include below($md, $orientation: portrait) {
      font-size: 3.7vw;
    }
    @include below($md, $orientation: landscape) {
      font-size: 2.3vw;
    }
    &:not(.loaded) {
      main {
        opacity: 0
      }
    }
}
body {
    @include ff-text();
    @include fs-normal();
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: normal;
}

.text--lead {
    @include text--lead();
}
.text--large-title {
    @include text--large-title();
}

.rich-text {
    @include rich-text();
}

// Shared
.underline {
    text-decoration: underline;
}
.uppercase {
    text-transform: uppercase;
}
.text-center {
    text-align: center;
}
.mt-1 {
    margin-top: 1em;
}
.mb-1 {
    margin-bottom: 1em;
}
.py-p {
  padding-top: $padding--desktop;
  padding-bottom: $padding--desktop;
}
.px-p {
  padding-left: $padding--desktop;
  padding-right: $padding--desktop;
}
.pb-p {
  padding-bottom: $padding--desktop;
}
