@mixin hideselect {
    backface-visibility: hidden;
    user-select: none;
    transform-style: flat;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-drag: none;
    outline: 0;
}

@mixin rich-text() {
  a {
      text-decoration: underline;
    }
    ul {
        list-style: none;
        padding: 0;
        margin: 0 0;
        &:not(:last-child) {
            margin-bottom: 1em;
        }
        li {
            position: relative;
            padding-left: 0;
            margin-left: 1.5em;
          &::before {
              content: '\002022';
              position: absolute;
              left: -1.5em;
          }
        }
    }
    ol {
        list-style: auto;
        padding: 0;
        margin: 0 0;
        counter-reset: countkeep;
        &:not(:last-child) {
            margin-bottom: 1em;
        }
        li {
            position: relative;
            padding-left: 3ch;
            &:before {
                position: absolute;
                left: 0;
                top: 0;
                counter-increment: countkeep;
                content: counter(countkeep) ".";
            }
        }
    }
}
