* {
    touch-action: manipulation;
    box-sizing: border-box;
    touch-action: manipulation;
    &:not(input) {
        @include hideselect();
    }
}
a, a:visited {
    color: inherit;
    text-decoration: none;
    cursor: pointer;
}
#loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $color--background-main;
  z-index: 1000;
}
.desktop {
    @include below($md) {
        display: none !important;
    }
}
.mobile {
    @include above($md+1) {
        display: none !important;
    }
}
.hidden {
    display: none !important;
}
.uppercase {
    text-transform: uppercase;
}
.text-center {
    text-align: center;
}
.lazy {
    opacity: 0;
    transition: opacity 200ms ease;
}
.lazy.no-fade {
    transition: opacity 0ms ease;
}
.lazy-blur {
    transition: filter 150ms ease;
    filter: blur(5px);
}
.lazy, .lazy-blur {
    &.lazyloaded, &.flickity-lazyloaded {
        opacity: 1;
        filter: none;
    }
}
video.lazy{
    &.lazyloading {
        opacity: 1;
        filter: none;
    }
}
body {
    margin: 0;
    padding: 0;
    font-size: 1rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
h1 {
    font-size: inherit;
    margin: 0;
}
p {
    line-height: inherit;
    &:not(:last-child) {
        margin-bottom: 1em;
    }
}
#viewport {
    display: block;
    width: 100%;
    float: left;
}
