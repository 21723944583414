input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 1000px $color--background-main inset !important;
  -webkit-text-fill-color: $color--font-secondary !important;
  background-color: $color--background-main !important;
  background-clip: content-box !important;
}

input:-internal-autofill-selected,
select:-internal-autofill-selected,
input:-internal-autofill-selected,
select:-internal-autofill-selected {
  background-color: $color--background-main !important;
  background-clip: content-box !important;
}


body {
    background: $color--background-main;
    color: $color--font-primary;
}

html[data-mode=teaser] {
  background: #000;
  body, #loader {
    background: #000;
  }
}

.accent, a.accent, a.accent:visited {
  color: $color--font-accent;
}

.white, a.white, a.white:visited {
  color: $color--white;
}

.primary, a.primary, a.primary:visited {
  color: $color--font-primary;
}

.primary-hover, a.primary-hover, a.primary-hover:visited {
  @include above($md + 1) {
    &:hover {
      color: $color--font-primary;
    }
  }
}
