/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
html {
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}


img { display: block; }

a { color: #222; text-decoration: none; }

h1, h2, h3, h4 { font-weight: normal;  }

b, strong { font-weight:normal; }
em { font-style:normal; }

::-moz-selection { background: #000; color:#FFF; text-shadow: none; }
::selection { background: #000; color:#FFF; text-shadow: none; }

hr { color: #222; background-color: #222; height:1px; border: none; }

* { -webkit-tap-highlight-color: rgba(0,0,0,0); }



button { -webkit-appearance: button; cursor: pointer; border:none; }
button::-moz-focus-inner { border: 0; padding: 0; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome
 *    (include `-moz` to future-proof).
 */

input[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}


/*  Input Placeholders  */

::-webkit-input-placeholder { color:#222; opacity: 1; }
input::-moz-placeholder { color:#222; opacity: 1; } /* firefox 19+ */
:-ms-input-placeholder { color:#222; opacity: 1; } /* ie */
input:-moz-placeholder { color:#222; opacity: 1; }

::-webkit-textarea-placeholder { color:#222; opacity: 1; }
textarea::-moz-placeholder { color:#222; opacity: 1; } /* firefox 19+ */
:-ms-textarea-placeholder { color:#222; opacity: 1; } /* ie */
textarea:-moz-placeholder { color:#222; opacity: 1; }



